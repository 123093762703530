export const metaTags = {
  default: {
    title: "Купить билет лото онлайн &mdash; Русская национальная лотерея &mdash; государственная лотерея сто лото &mdash; русское гослото",
    description: "Русская национальная государственная лотерея – выигрывайте реальные деньги! Купить билеты лото онлайн без регистрации",
    h1: "Ваш шанс на выигрыш в один клик! Лотерейные билеты онлайн",
  },
  nacionalnaya: {
    title: "Национальная государственная лотерея &mdash; Государственная лотерея &mdash; Купить лотерейный билет на сайте или через приложение",
    description: "Национальная государственная лотерея – Покупайте билеты на лото онлайн. Купить билеты лото онлайн без регистрации",
    h1: "Национальная государственная лотерея",
  },
  russkoe: {
    title: "Русское национальное лото &mdash; Купить лотерейный билет &mdash; Государственная лотерея",
    description: "Купить билет русского лото онлайн или в приложении на телефоне без регистрации",
    h1: "Русская государственная лотерея",
  },
  gosloto: {
    title: "Государственное лото &mdash; Купить билет гослото &mdash; Государственные лотереи онлайн",
    description: "Купить билет лотереи гослото онлайн или через приложение на телефоне без регистрации",
    h1: "Государственное лото: купить билет",
  },
  stoloto: {
    title: "Купить билет столото &mdash; Государственные лотереи 100 лото &mdash; Купить лотерейный билет без регистрации",
    description: "Купить билет 100 лото на сайте онлайн и через приложение на телефоне",
    h1: "Столото купить билет",
  },
  sportloto: {
    title: "Купить билет спортлото &mdash; Государственные лотереи спортлото &mdash; Купить лотерейный билет без регистрации",
    description: "Купить билет спортлото на официальном сайте онлайн и через приложение на телефоне",
    h1: "Спортлото купить билет",
  },
  jilishnaya: {
    title: "Жилищная лотерея &mdash; Купить билет жилищное лото онлайн",
    description: "Купить билет жилищной лотереи на официальном сайте онлайн и через приложение на телефоне",
    h1: "Жилищная лотерея купить билет",
  },
  momentalnaya: {
    title: "Моментальная лотерея онлайн &mdash; Купить билет моментального лото с выводом денег",
    description: "Государственные моментальные лотереи – купить билет моментального лото с выводом",
    h1: "Моментальная лотерея купить билет",
  },
};
